import React from 'react';
import s from './style.module.scss';
import { PageHeader } from '@root/components';

const Header: React.FC = () => {
    return(
        <div className={s.container}>
            <PageHeader title="Укажите маршрут для Вашего экспортного груза" additionalInfo="Выберите лучшую опцию и забронируйте"/>
        </div>
    );
}

export default Header;