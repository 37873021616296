import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './dateInput.module.scss';
import Popup from '@components/demoForm/inputs/Popup';
import moment, { Moment } from 'moment';
import TextInput from '@components/demoForm/inputs/TextInput';

interface DateInputProps {
	label: string;
	name: string;
	value: Date;
	onChange: (value: Date) => void;
	isAvailable: (value: Date) => boolean;
}

const DateInput: React.FC<DateInputProps> = (
	{
		label,
		name,
		value,
		onChange = (): void => void 0,
		isAvailable = (): boolean => true
	}) => {

	moment.locale('ru');
	const cx = classNames.bind(styles);
	const [showCalendar, setShowCalendar] = useState(false);
	const [isPrevRangeAvailable, setIsPrevRangeAvailable] = useState(false);
	const [firstDate, setFirstDate] = useState(moment(value ?? new Date()).utc(true).startOf('month').startOf('week'));
	const [mainMonth, setMainMonth] = useState<Moment>(firstDate.clone().endOf('week'));
	const [monthName, setMonthName] = useState('');
	const [dateRange, setDateRange] = useState<Date[][]>([]);

	useEffect(() => {
		const newMainMonth = firstDate.clone().endOf('week');
		setMainMonth(newMainMonth);
		const newMonthName = moment.months()[newMainMonth.month()];
		setMonthName(newMonthName[0].toLocaleUpperCase() + newMonthName.substring(1));
		const dates: Date[][] = [];
		for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
			dates.push(Array.from(Array(7).keys()).map(index => firstDate.clone().add(index + rowIndex * 7, 'day').toDate()));
		}

		setDateRange(dates);
		setIsPrevRangeAvailable(isAvailable(firstDate.clone().add(-1, 'day').toDate()));
	}, [firstDate]);

	const onInternalChange = (date: Date): void => {
		if (!isAvailable(date)) {
			return;
		}
		onChange(date);
		setShowCalendar(false);
	};

	const getFormattedValue = (): string => {
		if (value == null) {
			return '';
		}

		const json = value.toJSON();
		if (json == null) {
			return '';
		}
		return json.split('T')[0];
	};

	return <div className={classNames(styles.input, styles.date)}>
		<TextInput label={label} name={name} type='date' value={getFormattedValue()}
				   onChange={(value): void => onChange(new Date(value))}>
			<span className={classNames(styles.icon, styles['content-center'], styles['icon-sm'])}
				  role='icon'
				  onClick={(): void => setShowCalendar(true)}>
				<svg fill='none'
					 xmlns='http://www.w3.org/2000/svg'
					 viewBox='0 0 18 18'>
					<path
						d='M14.25 3H3.75a1.5 1.5 0 0 0-1.5 1.5V15a1.5 1.5 0 0 0 1.5 1.5h10.5a1.5 1.5 0 0 0 1.5-1.5V4.5a1.5 1.5 0 0 0-1.5-1.5ZM12 1.5v3M6 1.5v3M2.25 7.5h13.5'
						stroke='#3796F6'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round' />
				</svg>
			</span>
		</TextInput>
		<Popup show={showCalendar} setShow={setShowCalendar} className={styles['date-calendar']}>
			<div className={styles.calendar}>
				<div className={styles['calendar-header']}>
					{isPrevRangeAvailable &&
						<div className={classNames(styles['calendar-button'], styles['calendar-button-decrease'])}
							 onClick={(): void => setFirstDate(firstDate.clone().subtract(35, 'days'))}>
							<i className={styles.chevron}>
								<svg width='20' height='20' viewBox='0 0 20 20' fill='none'
									 xmlns='http://www.w3.org/2000/svg'>
									<path d='M7 4L13 10L7 16'
										  stroke='#9c9c9c'
										  strokeWidth='2'
										  strokeLinecap='round'
										  strokeLinejoin='round' />
								</svg>
							</i>
						</div>}
					<div className={styles['calendar-header-month']}>
						<strong className={styles['calendar-month-name']}>{monthName}</strong> {mainMonth.year()}
					</div>
					<div className={classNames(styles['calendar-button'], styles['calendar-button-increase'])}
						 onClick={(): void => setFirstDate(firstDate.clone().add(35, 'days'))}>
						<i className={styles.chevron}>
							<svg width='20'
								 height='20'
								 viewBox='0 0 20 20'
								 fill='none'
								 xmlns='http://www.w3.org/2000/svg'>
								<path d='M7 4L13 10L7 16'
									  stroke='#9c9c9c'
									  strokeWidth='2'
									  strokeLinecap='round'
									  strokeLinejoin='round' />
							</svg>
						</i></div>
				</div>
				<table className={styles['calendar-table']}>
					<tbody>
					<tr className={styles['calendar-row']}>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Пн</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Вт</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Ср</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Чт</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Пт</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Сб</th>
						<th className={classNames(styles['calendar-cell'], styles['calendar-day-name'])}>Вс</th>
					</tr>
					{dateRange.map((row, index) =>
						<tr key={index} className={classNames(styles['calendar-row'], styles['calendar-week'])}>
							{row.map((date, dateIndex) =>
								<td key={dateIndex}
									className={cx({
										'calendar-cell': true,
										'calendar-date': true,
										'calendar-is-other-month': date.getMonth() != mainMonth.month(),
										'calendar-is-current-month': date.getMonth() == mainMonth.month(),
										'calendar-is-ok': isAvailable(date)
									})}
									onClick={(): void => onInternalChange(date)}>
									{date.getDate()}
								</td>)}
						</tr>)}
					</tbody>
				</table>
			</div>
		</Popup>
	</div>;
};

export default DateInput;