import { useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { AirwaybillDto } from "@models/awbs/awbsModels";
import nameof from "ts-nameof.macro";
import React, { useEffect } from "react";
import styles from "@scenes/customerApplication/awb/components/airWaybillEditor/index.module.scss";
import { Col, Row } from "reactstrap";
import InputField from "@scenes/customerApplication/awb/components/InputField";
import { LocationSelectField } from "@scenes/customerApplication/awb/components/LocationSelectField";

export const AgentBlock = (props: { disabled: boolean, storedData: Record<string, string> }) => {
    const {t} = useTranslation();
    const context = useFormikContext<AirwaybillDto>();
    const [agentField] = useField<AirwaybillDto>(nameof<AirwaybillDto>((x) => x.agentName));
    const [agentCityField] = useField<AirwaybillDto>(nameof<AirwaybillDto>((x) => x.agentCityId));
    const [agentIataField] = useField<AirwaybillDto>(nameof<AirwaybillDto>((x) => x.agentIataCode));
    const [agentNumberField] = useField<AirwaybillDto>(nameof<AirwaybillDto>((x) => x.issuingCarrierAgentAccountNumber));

    useEffect(() => {
        const updatedFields = { ...context.values };
        let updateFields = false;

        if (props.disabled || props.storedData == null) {
            return;
        }

        const storedAgentName = props.storedData[nameof<AirwaybillDto>((x) => x.agentName)] || '';
        const storedAgentCityId = props.storedData[nameof<AirwaybillDto>((x) => x.agentCityId)] || '';
        const storedAgentIataCode = props.storedData[nameof<AirwaybillDto>((x) => x.agentIataCode)] || '';
        const storedAgentNumber = props.storedData[nameof<AirwaybillDto>((x) => x.issuingCarrierAgentAccountNumber)] || '';
        if (storedAgentName != '' && (agentField.value || '') == '') {
            updatedFields.agentName = storedAgentName;
            updateFields = true;
        }
        if (storedAgentCityId != '' && (agentCityField.value || '') == '') {
            updatedFields.agentCityId = storedAgentCityId;
            updateFields = true;
        }
        if (storedAgentIataCode != '' && (agentIataField.value || '') == '') {
            updatedFields.agentIataCode = storedAgentIataCode;
            updateFields = true;
        }
        if (storedAgentNumber != '' && (agentNumberField.value || '') == '') {
            updatedFields.issuingCarrierAgentAccountNumber = storedAgentNumber;
            updateFields = true;
        }
        if (updateFields) {
            context.setValues(updatedFields, false);
        }
    }, [props]);

    return <div className={styles.formBlock} data-cy={"agentName"}>
        <h3>{t('awb.issuingCarriersAgent')}</h3>
        <Row>
            <Col>
                <InputField
                    disabled={props.disabled}
                    type={'text'}
                    name={nameof<AirwaybillDto>((x) => x.agentName)}
                    label={t('awb.agentName')}
                    required
                />
            </Col>
        </Row>
        <Row>
            <Col data-cy={"agentCityId"}>
                <LocationSelectField
                    locationFieldName={nameof<AirwaybillDto>((x) => x.agentCityId)}
                    disabled={props.disabled}
                    placeholder={t('awb.agentCity')}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField
                    disabled={props.disabled}
                    type={'text'}
                    name={nameof<AirwaybillDto>((x) => x.agentIataCode)}
                    label={t('awb.iataCode')}
                    hasHint
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <InputField
                    disabled={props.disabled}
                    type={'text'}
                    name={nameof<AirwaybillDto>((x) => x.issuingCarrierAgentAccountNumber)}
                    label={t('awb.accountNo')}
                />
            </Col>
        </Row>
    </div>;
}