import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@i18n';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from '@root/enhancements';
import SessionManager from '@root/SessionManager';
import { BrowserRouter } from 'react-router-dom';

SessionManager.initAsync().then(() => {
	ReactDOM.render(
		<Provider store={store}>
			<BrowserRouter>
				<App />
				<ToastContainer />
			</BrowserRouter>
		</Provider>,
		document.getElementById('root')
	);
});