import * as React from 'react';
import { useTranslation } from "react-i18next";
import { ApplicationState, useAppDispatch } from "@root/store";
import { useSelector } from "react-redux";
import { changeFwbVersion, getFwbCode, sendFwb, State } from "@store/airwaybills/airwaybillsStore";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useClipboard } from 'use-clipboard-copy';
import clsx from "clsx";
import { FwbVersion } from "@models/awbs/awbsModels";

type Props = {
    airwaybillId: string;
    onSendFwbCompleted?: () => void;
}

const FwbPage = (props: Props) => {
    const {fwbCode, isFetching} = useSelector<ApplicationState, State>(x => x.airwaybills);
    const dispatch = useAppDispatch();
    const clipboard = useClipboard({copiedTimeout: 1000});
    const {t} = useTranslation();
    const [version, setVersion] = useState<FwbVersion>(FwbVersion.None);

    useEffect(() => {
        dispatch(getFwbCode(props.airwaybillId));
    }, [props.airwaybillId]);

    useEffect(() => {
        if (fwbCode == null) {
            return;
        }

        if (fwbCode.startsWith('FWB/17')) {
            setVersion(FwbVersion.Version17);
        }

        if (fwbCode.startsWith('FWB/16')) {
            setVersion(FwbVersion.Version16);
        }
    }, [fwbCode]);

    const sendFwbCode = (e) => {
        dispatch(sendFwb({id: props.airwaybillId, fwbCode: fwbCode}))
            .then(_ => {
                if (props.onSendFwbCompleted) {
                    props.onSendFwbCompleted();
                }
            });
    }

    const onVersionChange = (version: FwbVersion) => {
        setVersion(version);
        dispatch(changeFwbVersion({id: props.airwaybillId, version: version}));
    }

    return <>
        <h2>{t('awb.fwb')}</h2>
        <Row className="mb-3">
            <Col>
                <textarea disabled={true} ref={clipboard.target} className="form-control" style={{height: 200}}
                          value={fwbCode}/>
            </Col>
        </Row>
        <Row>
            <Col style={{display: 'flex', gap: 10}}>
                <span>{t('awb.fwbVersion')}</span>
                <label><input style={{marginRight: 10}}
                              disabled={isFetching}
                              type="radio"
                              value="16"
                              checked={version === FwbVersion.Version16}
                              onChange={() => onVersionChange(FwbVersion.Version16)}/>16</label>
                <label><input style={{marginRight: 10}}
                              disabled={isFetching}
                              type="radio"
                              value="17"
                              checked={version === FwbVersion.Version17}
                              onChange={() => onVersionChange(FwbVersion.Version17)}/>17</label>
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <button
                    disabled={isFetching}
                    onClick={clipboard.copy}
                    className={clsx("btn", !clipboard.copied ? "btn-primary" : "btn-secondary")}>
                    {clipboard.copied ? t('awb.copied') : <><i
                        className="icon-inline icon-copy"/> {t('awb.copyCode')}</>}
                </button>
                {"  "}
                <button className="btn btn-primary"
                        disabled={isFetching}
                        onClick={sendFwbCode}>
                    <i className="icon-inline icon-mail"/> {t('awb.send')}
                </button>
            </Col>
        </Row>
    </>;
};

export default FwbPage;