import React, { PropsWithChildren, SyntheticEvent } from 'react';
import cn from 'classnames';
import Loader from '../Loader/index';
import { ButtonTypes, ButtonVariants } from '../../constants';
import s from './button.module.scss';

export interface IButton {
	type: ButtonTypes;
	variant: ButtonVariants;
	icon?: React.ReactNode;
	iconPosition?: 'start' | 'end';
	className?: string;
	title?: string;
	disabled?: boolean;
	loading?: boolean;
	onClick?(e?: SyntheticEvent): void;
	size?: 'small' | 'default' | 'large';
}

const Button: React.FC<PropsWithChildren<IButton>> = ({
	children,
	icon,
	className,
	onClick,
	type,
	iconPosition = 'start',
	title,
	disabled,
	loading,
	variant,
	size,
}) => {
	return (
		<button
			title={title}
			onClick={onClick}
			disabled={disabled || loading}
			className={cn([
				s.button,
				[s[size]],
				{
					[s.reverse]: iconPosition === 'end',
					[s.fillPrimary]: type === 'fill' && variant === 'primary',
					[s.dottedPrimary]: type === 'dotted' && variant === 'primary',
					[s.fillSecondary]: type === 'fill' && variant === 'secondary',
					[s.fillSuccess]: type === 'fill' && variant === 'success',
					[s.fillWarning]: type === 'fill' && variant === 'warning',
					[s.fillError]: type === 'fill' && variant === 'error',
					[s.fillWhite]: type === 'fill' && variant === 'white',
					[s.fillInfo]: type === 'fill' && variant === 'info',
					[s.fillSupport]: type === 'fill' && variant === 'support',
					[s.outlinePrimary]: type === 'outline' && variant === 'primary',
					[s.outlineSecondary]: type === 'outline' && variant === 'secondary',
					[s.outlineSuccess]: type === 'outline' && variant === 'success',
					[s.outlineWarning]: type === 'outline' && variant === 'warning',
					[s.outlineError]: type === 'outline' && variant === 'error',
					[s.outlineWhite]: type === 'outline' && variant === 'white',
					[s.outlineInfo]: type === 'outline' && variant === 'info',
					[s.outlineSupport]: type === 'outline' && variant === 'support',
					[s.textPrimary]: type === 'text' && variant === 'primary',
					[s.textSecondary]: type === 'text' && variant === 'secondary',
					[s.textSuccess]: type === 'text' && variant === 'success',
					[s.textWarning]: type === 'text' && variant === 'warning',
					[s.textError]: type === 'text' && variant === 'error',
					[s.textWhite]: type === 'text' && variant === 'white',
					[s.textInfo]: type === 'text' && variant === 'info',
					[s.textSupport]: type === 'text' && variant === 'support',
				},
				className,
			])}
			type="button"
		>
			{(disabled || loading) && type !== 'text' && <span className={s.disable} />}
			{icon && (
				<span style={!children ? { margin: 0 } : undefined} className={s.icon}>
					{icon}
				</span>
			)}
			{children && children}
			{loading && <Loader className={s.loader} />}
		</button>
	);
};

export default Button;
