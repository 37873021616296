import React from 'react';
import styles from './LoadingSpinner.module.scss';

interface Props {
    searchText?: string;
    size?: string;
    disableMargin?: boolean;
}

const LoadingSpinnerComponent: React.FC<Props> = ({searchText, size, disableMargin}) => {
    return (
        <>
            <div
                className={styles.loader}
                style={{
                    fontSize: size ?? '90px',
                    margin: !!disableMargin ? '0 0 0 0' : '72px auto',
                }}
            />
            <p className="text-center">{searchText}</p>
        </>
    );
};

export default LoadingSpinnerComponent;
