import * as React from 'react';
import { Field, FieldArray, FieldProps, FormikProps, getIn } from 'formik';
import { Button, ButtonGroup, Input } from 'reactstrap';
import styles from './style.module.scss';
import InputNumber from '@components/InputNumber';
import { useTranslation } from 'react-i18next';
import nameof from 'ts-nameof.macro';
import { CreateCustomerRequestModel } from '@models/customerRequests/formModels';
import { nullIfNan } from '@helpers/Numbers';
import { CargoDimensionDto } from '@models/customerRequests/customerRequestModels';
import { Button as Btn } from '@root/components';
import { ReactComponent as IconAdd } from '@material-design-icons/svg/round/add.svg';
import { ReactComponent as IconDelete } from '@material-design-icons/svg/round/delete.svg';
import FieldError from '@scenes/customerRequest/searchForm/components/FieldError';
import { recalculateCargoDetails } from '@scenes/request/helpers';
import cn from 'classnames';

interface Props {
    formikProps: FormikProps<CreateCustomerRequestModel>;
}

const dim: CargoDimensionDto = {
    amount: 1,
    weight: 0,
    height: 0,
    length: 0,
    width: 0,
    weightPerPlace: false,
};

const Dims = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const { formikProps } = props;

    const labels = [
        'request.items',
        'request.itemLength',
        'request.itemWidth',
        'request.itemHeight',
        'request.itemWeight',
        'request.weightType',
        'actions',
    ];

    return (
        <FieldArray
            name={nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}
            render={({ push, remove }): JSX.Element => (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <span className={styles.tableHeaderText}>Таблица габаритов</span>
                    </div>

                    {formikProps?.values?.cargoDimensions?.length > 0 &&
                        <div className={styles.labels}>
                            {labels.map((l, index) =>
                                <span className={styles.label} key={index}>{t(l)}</span>,
                            )}
                        </div>
                    }

                    <div className={styles.rows}>
                        {formikProps?.values?.cargoDimensions?.map((cargoDimension, index) => {
                                const fieldPath = `${nameof.full<CreateCustomerRequestModel>(x => x.cargoDimensions)}[${index}]`;

                                return (
                                    <div key={index} className={styles.row}>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.items')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                                <Field name={`${fieldPath}.amount`}>
                                                    {({ field, form }: FieldProps) =>
                                                        <InputNumber
                                                            id={field.name}
                                                            hasError={
                                                                getIn(form.errors, field.name) && getIn(form.touched, field.name)
                                                            }
                                                            {...field}
                                                            onBlur={form.setFieldTouched}
                                                            onChange={(name, value) => {
                                                                let val: any = value;
                                                                if (val != null) {
                                                                    val = nullIfNan(parseInt(val)) || '';
                                                                }
                                                                formikProps.setFieldValue(field.name, val);
                                                            }}
                                                        />
                                                    }
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemLength')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                                <Field name={`${fieldPath}.length`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <div className='inputControl'>
                                                            <Input
                                                                onFocus={e => {e.target.value = '';}}
                                                                placeholder={t('units.cm')}
                                                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                                                {...field}
                                                            />
                                                            <FieldError
                                                                error={form.errors[field.name] as string}
                                                                isTouched={form.touched[field.name] as boolean}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemWidth')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                                <Field name={`${fieldPath}.width`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <div className='inputControl'>
                                                            <Input
                                                                onFocus={e => {e.target.value = '';}}
                                                                placeholder={t('units.cm')}
                                                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                                                {...field}
                                                            />
                                                            <FieldError
                                                                error={form.errors[field.name] as string}
                                                                isTouched={form.touched[field.name] as boolean}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemHeight')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                                <Field name={`${fieldPath}.height`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <div className='inputControl'>
                                                            <Input
                                                                onFocus={e => {e.target.value = '';}}
                                                                placeholder={t('units.cm')}
                                                                className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                                                {...field}
                                                            />
                                                            <FieldError
                                                                error={form.errors[field.name] as string}
                                                                isTouched={form.touched[field.name] as boolean}
                                                            />
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.itemWeight')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                            <Field name={`${fieldPath}.weight`}>
                                                {({ field, form }: FieldProps) => (
                                                    <div className='inputControl'>
                                                        <Input
                                                            onFocus={e => {e.target.value = '';}}
                                                            placeholder={t('units.kg')}
                                                            className={getIn(form.errors, field.name) && getIn(form.touched, field.name) ? 'inputControlError' : ''}
                                                            {...field}
                                                        />
                                                        <FieldError
                                                            error={form.errors[field.name] as string}
                                                            isTouched={form.touched[field.name] as boolean}
                                                        />
                                                    </div>
                                                )}
                                            </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <span className={cn(styles.label, styles.labelMobile)}>
                                                {t('request.weightType')}
                                            </span>
                                            <div className={styles.inputMobile}>
                                                <Field name={`${fieldPath}.weightPerPlace`}>
                                                    {({ field, form }: FieldProps) => (
                                                        <ButtonGroup size='sm' style={{width: "100%"}}>
                                                            <Button
                                                                color='primary'
                                                                outline={field.value}
                                                                onClick={() => {
                                                                    form.setFieldValue(field.name, false);
                                                                    recalculateCargoDetails(formikProps);
                                                                }}
                                                            >Общий</Button>
                                                            <Button
                                                                color='primary'
                                                                outline={!field.value}
                                                                onClick={() => {
                                                                    form.setFieldValue(field.name, true);
                                                                    recalculateCargoDetails(formikProps);
                                                                }}>
                                                                На место
                                                            </Button>
                                                        </ButtonGroup>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.col}>
                                            <Btn
                                                type='text'
                                                icon={<IconDelete fill='currentColor' />}
                                                variant='error'
                                                onClick={() => remove(index)}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                        )}
                    </div>
                    <div className={styles.btn}>
                        <Btn
                            type='fill'
                            icon={<IconAdd fill='currentColor' />}
                            variant='primary'
                            onClick={() => push(dim)}
                        />
                    </div>
                </div>

            )}
        />
    );
};

export default Dims;
