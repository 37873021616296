import AuthorizedHttpClient from '@core/AuthorizedHttpClient';
import { HttpError } from "@models/shared";
import { LocationDto, LocationType } from "@models/locations";

export default class LocationsService {

	public getLocations = async (term?: string, type?: LocationType, hideWithoutTariffs?: boolean) => {

		let url = `api/locations/${term}`;
		const params = [];

		if (type != null) {
			params.push(`type=${type}`);
		}

		if (hideWithoutTariffs != null) {
			params.push(`hideWithoutTariffs=${hideWithoutTariffs}`);
		}

		if (params.length > 0) {
			url += `?${params.join('&')}`;
		}

		return await AuthorizedHttpClient.get<LocationDto[] & HttpError>(url);
	}

	public getKnownLocations = async (term?: string, type?: LocationType, fromCustomerApplications?: boolean) => {

		const q = [
			term != null ? `term=${term}` : null,
			type != null ? `type=${type}` : null,
			fromCustomerApplications != null && fromCustomerApplications == true ?
				`fromCustomerApplications=${true}` : null
		].join('&');

		return await AuthorizedHttpClient.get<LocationDto[] & HttpError>(`api/locations/known?${q}`);
	}

	public getLocationById = async (locationId: string) => {
		return await AuthorizedHttpClient.get<LocationDto & HttpError>(`api/locations/${locationId}`);
	}

    createLocationAsync(countryId: string, cityName: string) {
        return AuthorizedHttpClient.post<LocationDto & HttpError>('api/locations', {countryId: countryId, cityName: cityName});
    }
}