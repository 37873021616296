import { FieldArray, FormikProps } from "formik";
import { ContactListItemDto, CreateContact } from "@models/contacts";
import { InteractionType } from "@models/contacts/InteractionType";
import nameof from "ts-nameof.macro";
import React, { useEffect, useState } from "react";
import {
    AccreditationModel,
    ContactInfoDto
} from "@models/organizations/accreditationModels";
import { InteractionContact } from "@scenes/accreditationList/components/InteractionContact";
import AppModal from "@components/AppModal";
import { Col, Row } from "reactstrap";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import PersonalInfoEditor, { EditorMode } from "@scenes/accountSettings/components/PersonalInfoEditor";
import SessionManager from "@root/SessionManager";
import { toast } from "react-toastify";
import { interactionTypesOrderedArray } from "@helpers/accreditationHelpers";

interface Props {
    formikProps: FormikProps<AccreditationModel>;
}

export const InteractionContacts = ({formikProps}: Props) => {
    const {t} = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const [replacedContactId, setReplacedContactId] = useState('');
    const [editedContact, setEditedContact] = useState<ContactInfoDto>(null);
    const [contactMap, setContactMap] = useState<Record<string, ContactInfoDto>>({});
    const [editedContacts, setEditedContacts] = useState<Record<string, boolean>>({});
    const [interactionMap, setInteractionMap] = useState<Record<string, string[]>>({});

    useEffect(() => {
        const contacts = formikProps.values.contacts;
        if (contacts == null || contacts.length === 0) {
            setContactMap({});
            return;
        }

        const contactMap: Record<string, ContactInfoDto> = {};
        for (const contact of contacts) {
            contactMap[contact.id] = contact;
        }

        setContactMap(contactMap);
    }, [formikProps]);

    useEffect(() => {
        const interactionMap: Record<string, string[]> = {};

        for (const interactionType of interactionTypesOrderedArray) {
            const typeKey = InteractionType[interactionType];
            interactionMap[typeKey] = [''];
            const interaction = formikProps.values.interactionMap[typeKey];
            if(interaction != null && interaction.length > 0) {
                interactionMap[typeKey] = interaction;
            }
        }

        setInteractionMap(interactionMap);
    }, [formikProps.values.interactionMap]);

    const addContact = (id: string, typeKey: string) => {
        const existingInteractions = interactionMap[typeKey];
        const newInteractions = existingInteractions == null ? [id] : [...existingInteractions, id];
        let newInteractionMap = {};
        if (existingInteractions == null) {
            interactionMap[typeKey] = newInteractions;

            for (const interactionType in interactionTypesOrderedArray) {
                const typeKey = InteractionType[interactionType];
                newInteractionMap[typeKey] = interactionMap[typeKey];
                delete interactionMap[typeKey];
            }

            newInteractionMap = {...newInteractionMap, interactionMap};
        } else {
            newInteractionMap = {
                ...interactionMap,
                [typeKey]: newInteractions
            };
        }

        formikProps.setFieldValue(nameof<AccreditationModel>(x => x.interactionMap), newInteractionMap);
    };

    const replaceContact = (oldContactId: string, newId: string, typeKey: string) => {
        const newInteractions = interactionMap[typeKey].map(id => id == oldContactId ? newId : id);

        formikProps.setFieldValue(nameof<AccreditationModel>(x => x.interactionMap),
            {
                ...interactionMap,
                [typeKey]: newInteractions
            });
    };

    const removeContact = (oldContactId: string, typeKey: string) => {
        const newInteractions = interactionMap[typeKey].filter(id => id != oldContactId);

        formikProps.setFieldValue(nameof<AccreditationModel>(x => x.interactionMap),
            {
                ...interactionMap,
                [typeKey]: newInteractions
            });
    };

    const showContactForm = (interactionType: InteractionType, replacedContactId: string) => {

        setEditedContact({
            email: '',
            lastName: '',
            firstName: '',
            jobTitle: '',
            phone: '',
            canLogin: true,
            interactionTypes: [interactionType],
            organizationId: SessionManager.user.organizationId
        });
        setReplacedContactId(replacedContactId);
        setShowForm(true);
    };

    const updateEditedContacts = (contact: ContactInfoDto, isOpen: boolean) => {
        setEditedContacts({...editedContacts, [contact.id]: isOpen});
    }

    const updateContact = (contact: ContactInfoDto) => {
        formikProps.setFieldValue(nameof<AccreditationModel>(x => x.contacts), formikProps.values.contacts.map(c => c.id === contact.id ? contact : c));

        if (!formikProps.values.contacts.find(c => c.id != contact.id && c.email == contact.email)) {
            return;
        }

        const interactionMapErrors = {};
        const userAlreadyExistsError = t('exceptions.AccountRegistrationException.UserAlreadyExists');

        for (const interactionName of Object.keys(interactionMap)) {
            const interaction = interactionMap[interactionName];
            if (interaction.includes(contact.id)) {
                interactionMapErrors[interactionName] = [{email: userAlreadyExistsError}];
            }
        }

        setTimeout(() => formikProps.setErrors({interactionMap: interactionMapErrors}), 100);
    };

    return (
        <>
            <FieldArray
                name={nameof<AccreditationModel>((x) => x.contacts)}
                render={() => {
                    let rowIndex = 0;
                    return Object.keys(interactionMap)
                        .map((key) =>
                            interactionMap[key]
                                .map((id, idx) => <InteractionContact
                                    index={idx}
                                    key={rowIndex ++}
                                    formikProps={formikProps}
                                    contact={contactMap[id]}
                                    interactionType={InteractionType[key]}
                                    onChange={contactId => contactId == '' ? showContactForm(InteractionType[key], id) : replaceContact(id, contactId, key)}
                                    onRemove={() => removeContact(id, key)}
                                    onAdd={() => addContact('', key)}
                                    onEdit={() => {
                                        setEditedContact(contactMap[id]);
                                        setShowForm(true);
                                    }}
                                    onUpdateContact={updateContact}
                                    onOpenEditor={updateEditedContacts}
                                    editedContacts={editedContacts}
                                    hideValidationErrors={showForm}
                                />));
                }}
            />
            {showForm && <AppModal isOpen={showForm} onClickCloseButton={() => setShowForm(false)} body={
                <PersonalInfoEditor<CreateContact>
                    hideInteractions={true}
                    data={editedContact}
                    showCanLogin={true}
                    mode={EditorMode.Create}
                    onSubmit={async data => {
                        if (editedContact.id) {
                            updateContact(data);
                            setEditedContact(null);
                            return {} as any;
                        }

                        const contact: ContactListItemDto = {
                            id: `new-contact-${nanoid()}`,
                            email: data.email,
                            lastName: data.lastName,
                            firstName: data.firstName,
                            canLogin: data.canLogin,
                            phone: data.phone,
                            inactive: false,
                            jobTitle: data.jobTitle,
                            interactionTypes: data.interactionTypes.map(type => ({
                                value: type,
                                key: InteractionType[type]
                            }))
                        };

                        if (formikProps.values.contacts.find(c => c.id != contact.id && c.email == contact.email)) {
                            return {
                                isError: true,
                                fields: [{
                                    name: 'email',
                                    messages: [t('exceptions.AccountRegistrationException.UserAlreadyExists')]
                                }]
                            };
                        }

                        await formikProps.setFieldValue(nameof<AccreditationModel>(x => x.contacts), [...formikProps.values.contacts, contact]);
                        replaceContact(replacedContactId, contact.id, InteractionType[editedContact.interactionTypes[0]]);
                        setReplacedContactId('');
                        setEditedContact(null);
                        return {} as any;
                    }}
                    onSuccess={() => {
                        setShowForm(false);
                    }}
                    onError={(msg) => {
                        toast.error(msg);
                    }}
                >
                    {(renderEditor, onClickSubmit) => {
                        return (
                            <>
                                {renderEditor()}
                                <Row>
                                    <Col className={'text-center mb-3 mt-4'}>
                                        <button
                                            type={'button'}
                                            className={'btn btn-primary'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onClickSubmit();
                                            }}
                                        >
                                            {t(editedContact && editedContact.id ? 'awb.edit' : 'create')}
                                        </button>
                                    </Col>
                                </Row>
                            </>
                        );
                    }}
                </PersonalInfoEditor>
            }/>}
        </>
    );
};
