import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@root/store';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Button } from 'reactstrap';
import { Loader } from '@components/index';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import Paginator from '@components/paginator/Paginator';
import dictionaryStyles from '../../../admin/components/DictionaryStyles.module.scss';
import { getAwbList } from '@store/airwaybills/airwaybillsStore';
import debounce from 'awesome-debounce-promise';
import { AwbListQuery } from '@models/awbs/awbsModels';
import LocationSelect from '@components/select/LocationSelect';
import LocationsService from '@services/LocationsService';
import { AirwaybillRow } from '@scenes/awb/components/list/AirwaybillRow';
import { RecognizeControl } from "@scenes/awb/components/edit/recognizeControl";

const AwbAllItemsPage = () => {
	const { t } = useTranslation();

	const { isFetching, airwaybills } = useAppSelector((x) => x.airwaybills);

	const [query, setQuery] = useState<AwbListQuery>({ awbNumber: '', pageNumber: 0, pageSize: 10 });

	const dispatch = useAppDispatch();
	React.useEffect(() => {
		dispatch(getAwbList(query));
	}, [query]);

	const onChangePage = (pageNumber: number): void => {
		setQuery({...query, pageNumber});
	};

	const history = useHistory();
	const goToAwbForm = (awbUId: string): void => {
        history.push(`/awb/edit/${awbUId}`);
    };
	const debouncedTermChanged = debounce((term: string) => {
		setQuery({...query, awbNumber: term, pageNumber: 0});
	}, 800);
	const onSearchByTerm = (term: string): void => {
		debouncedTermChanged(term?.trim() ?? '');
	};

	return (
		<>
			<Row className="mb-5 justify-content-end">
				<Button onClick={_ => history.push(`/awb/create`)} color="primary">
					{t('awb.createNewEmptyAwb')}
				</Button>&nbsp;&nbsp;
				<RecognizeControl onRecognize={id => history.push(`/awb/edit/${id}`)}/>
            </Row>

			<Row className="mb-3">
				<Col md={4}>
					<input
						type="text"
						className={clsx('form-control', dictionaryStyles.termFilter)}
						placeholder={t('awb.awbNumber')}
						onKeyUp={(e): void => onSearchByTerm(e.currentTarget.value)}
					/>
				</Col>
				<Col md={4}>
					<LocationSelect
						placeholder={t('awb.list.departureAirportSearch')}
						selectedOptions={query.departure || null}
						onChange={(x) => setQuery({...query, departure: x, pageNumber: 0})}
						fetch={term => new LocationsService().getLocations(term)}
						isMulti={true}
						isClearable={true}
					/>
				</Col>
				<Col md={4}>
					<LocationSelect
						placeholder={t('awb.list.arrivalAirportSearch')}
						selectedOptions={query.arrival || null}
						onChange={(x) => setQuery({...query, arrival: x, pageNumber: 0})}
						fetch={term => new LocationsService().getLocations(term)}
						isMulti={true}
						isClearable={true}
					/>
				</Col>
            </Row>

			{isFetching ? (
				<Loader />
			) : (
				<>
					<Row>
						<Col>
							<table className={clsx('table table-hover table-responsive-sm', dictionaryStyles.table)}>
								<thead>
									<tr>
										<th/>
										<th>{t('awb.list.hasFMA')}</th>
										<th>{t('awb.list.number')}</th>
										<th>{t('awb.list.departureAirport')}</th>
										<th>{t('awb.list.arrivalAirport')}</th>
										<th>{t('awb.list.currency')}</th>
										<th>{t('awb.list.physicalWeight')}</th>
										<th>{t('awb.list.paidWeight')}</th>
										<th>{t('awb.list.departureDate')}</th>
										<th>{t('awb.list.awbDate')}</th>
									</tr>
								</thead>
								<tbody>
									{airwaybills?.items.map((a, key) => <AirwaybillRow key={key} item={a} onClick={goToAwbForm}/>)}
								</tbody>
							</table>
						</Col>
					</Row>
					<Row>
						<Col>
							<Paginator
								totalResults={airwaybills?.count || 0}
								limitPerPage={query.pageSize}
								currentPage={query.pageNumber + 1}
								onChangePage={(pageNumber) => onChangePage(pageNumber)}
								pageNeighbours={4}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default AwbAllItemsPage;