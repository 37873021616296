import React, { useState, useMemo, useEffect } from "react";
import userItemsPageStyles from "@assets/userItemsPage.module.scss";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment from "moment";
import "@assets/reactCalendar.scss";
import LocationSelect from "@components/select/LocationSelect";
import { Col, Row } from "reactstrap";
import { LocationDto, LocationType } from "@models/locations";
import LocationsService from "@services/LocationsService";
import { Filter } from "@components/Filter";
import { GroupFilter } from "@components/Filter/groupFilter";
import { FilterConnection, FilterOperator, FilterValueCondition } from "@models/entityNavigation/filtering";
import { FieldFilter } from "@components/Filter/fieldFilter";
import { GroupOrFieldFilter } from "@components/Filter/groupOrFieldFilter";
import { useTranslation } from "react-i18next";
import debounce from "awesome-debounce-promise";
import styles from './CustomerRequestHistoryFilter.module.scss';
import clsx from "clsx";
export interface IDateFilterArgs {
	from?: string;
	to?: string;
}

type Props = {
	onChangeFilter: (filter: FilterConnection) => void;
};

const debounceNumberChange = debounce((updater: () => void) => updater(), 500, {});

const CustomerRequestsHistoryFilter: React.FC<Props> = ({onChangeFilter}) => {
	const [ createDateCalendarFocusedInput, setCreateDateCalendarFocusedInput ] = useState<FocusedInputShape>(null);
	const [ departureDateCalendarFocusedInput, setDepartureDateCalendarFocusedInput ] = useState<FocusedInputShape>(null);
	const [ locationsFrom, setLocationsFrom ] = useState<LocationDto[]>([]);
	const [ locationsTo, setLocationsTo ] = useState<LocationDto[]>([]);
	const [ createdDateFilter, setCreatedDateFilter ] = useState<IDateFilterArgs>({});
	const [ departureDateFilter, setDepartureDateFilter ] = useState<IDateFilterArgs>({});
	const [ requestNumber, setRequestNumber ] = useState('');
	const [ term, setTerm ] = useState('');

	const {t} = useTranslation();

	const filter = useMemo(() => {
		const getLocationsFilter = (locations: LocationDto[], cityFieldName: string, airportFieldName: string): GroupFilter[] => {
			return locations.length == 0
				? []
				: [ {
					type: 'group',
					operator: FilterOperator.Or,
					fields: locations.map(value =>
						({
							type: 'field',
							name: value.type == LocationType.City ? cityFieldName : airportFieldName,
							condition: FilterValueCondition.Equals,
							value: value.id
						}))
				} ];
		};

		const getDateFilter = (dateFilter: IDateFilterArgs, name: string): GroupFilter[] => {
			const startDate = dateFilter.to || '';
			const getFromFilter = (): FieldFilter[] => {
				return startDate == '' ? [] : [ {
					type: 'field',
					name: name,
					condition: FilterValueCondition.LessOrEqualsThan,
					value: startDate
				} ];
			};

			const endDate = dateFilter.from || '';
			return startDate == '' && endDate == ''
				? []
				: [
					{
						type: 'group',
						operator: FilterOperator.And,
						fields: [
							{
								type: 'field',
								name: name,
								condition: FilterValueCondition.MoreOrEqualsThan,
								value: endDate
							},
							...getFromFilter(),
						]
					}
				];
		}

		const getNumberFilter = (): FieldFilter[] => {
			return requestNumber == null || requestNumber.trim().length == 0
				? []
				: [ {
					type: 'field',
					condition: FilterValueCondition.Contains,
					value: requestNumber,
					name: 'number'
				} ];
		}

		const result: GroupOrFieldFilter = {
			type: 'group',
			operator: FilterOperator.And,
			fields: [
				...(getLocationsFilter(locationsFrom, "departureCityId", "departureAirportId")),
				...(getLocationsFilter(locationsTo, "destinationCityId", "destinationAirportId")),
				...(getDateFilter(createdDateFilter, 'createTime')),
				...(getDateFilter(departureDateFilter, 'dateStartPlan')),
				...getNumberFilter()
			],
		};

		return result;
	}, [ locationsFrom, locationsTo, createdDateFilter, departureDateFilter, requestNumber ]);

	useEffect(() => {
		debounceNumberChange(() => setRequestNumber(term));
	}, [ term ]);
	const minNumberDigitsRequired = 3;

	function renderDateFilter(title: string, focusedInput: FocusedInputShape, dateFilter: IDateFilterArgs, updateDateFilter: (value: IDateFilterArgs) => void, setFocusedInput: (value: FocusedInputShape) => void) {
		return <div className={userItemsPageStyles.filter__calendar}>
              <span className={userItemsPageStyles.filter__item__title}>
                {title}
              </span>
			<DateRangePicker
				focusedInput={focusedInput}
				isOutsideRange={() => null}
				startDatePlaceholderText={t("customerRequestsHistory.startDate")}
				endDatePlaceholderText={t("customerRequestsHistory.endDate")}
				startDate={
					dateFilter?.from
						? moment(dateFilter.from).startOf("day")
						: null
				}
				endDate={
					dateFilter?.to ? moment(dateFilter.to).endOf("day") : null
				}
				startDateId="your_unique_start_date_id"
				endDateId="your_unique_end_date_id"
				displayFormat={() => "DD/MM/YYYY"}
				customArrowIcon={<i className={`calendarArrowIcon icon-arrow-long`}/>}
				inputIconPosition="before"
				showDefaultInputIcon={true}
				customInputIcon={<i className={`calendarInputIcon icon-calendar`}/>}
				onDatesChange={({startDate, endDate}) => {
					const from = startDate?.startOf("day").toISOString();
					const to = endDate?.endOf("day").toISOString();
					updateDateFilter({from, to});
				}}
				onFocusChange={(inputName) => {
					setFocusedInput(inputName);
				}}
			/>
		</div>;
	}

	return (
		<Filter filterFields={filter} onFilter={onChangeFilter}>
			<div className={userItemsPageStyles.filter__block}>
				<div className={userItemsPageStyles.filter__top}>
					<span className={userItemsPageStyles.filter__title}>
					  {t("customerRequestsHistory.filterOptions")}
					</span>
					<a
						href="#"
						className={userItemsPageStyles.filter__clear}
						onClick={e => {
							e.preventDefault();
							setLocationsFrom([]);
							setLocationsTo([]);
							setCreatedDateFilter({});
							setDepartureDateFilter({});
							setRequestNumber(null);
						}}
					>
						{t("customerRequestsHistory.clearFilters")}
					</a>
				</div>
				<div className={userItemsPageStyles.filter__body}>
					<Row>
						<Col sm={6}>
							<div className={userItemsPageStyles.filter__location}>
              <span className={userItemsPageStyles.filter__item__title}>
                {t("customerRequestsHistory.source")}
              </span>
								<LocationSelect
									isClearable={true}
									isMulti={true}
									fetch={(term) => new LocationsService().getKnownLocations(term)}
									onChange={setLocationsFrom}
									loadingMessage={t("options.loadingOptions")}
									noOptionsMessage={t("options.noOptions")}
									placeholder={t("customerRequestsHistory.airportOrCity")}
									selectedOptions={locationsFrom}
									initializeWithEmptyTerm={true}
								/>
							</div>
						</Col>
						<Col sm={6}>
							<div className={userItemsPageStyles.filter__location}>
              <span className={userItemsPageStyles.filter__item__title}>
                {t("customerRequestsHistory.target")}
              </span>
								<LocationSelect
									isClearable={true}
									isMulti={true}
									fetch={(term) => new LocationsService().getKnownLocations(term)}
									onChange={setLocationsTo}
									loadingMessage={t("options.loadingOptions")}
									noOptionsMessage={t("options.noOptions")}
									placeholder={t("customerRequestsHistory.airportOrCity")}
									selectedOptions={locationsTo}
									initializeWithEmptyTerm={true}
								/>
							</div>
						</Col>
					</Row>
					<Row className={styles.dateNumberFilter}>
						{renderDateFilter(t("customerRequestsHistory.selectDateRange"), createDateCalendarFocusedInput, createdDateFilter, setCreatedDateFilter, setCreateDateCalendarFocusedInput)}
						{renderDateFilter(t("customerRequestsHistory.selectDepartureDateRange"), departureDateCalendarFocusedInput, departureDateFilter, setDepartureDateFilter, setDepartureDateCalendarFocusedInput)}
						<div className={userItemsPageStyles.filter__number}>
								<span className={userItemsPageStyles.filter__item__title}>
									{t('customerApplicationHistory.requestNo')}
								</span>
							<input
								className={clsx("form-control", styles.numberFilter)}
								value={term || ''}
								onChange={(x) => {
									const value = x.target.value;
									setTerm(value);
								}}
								placeholder={t('customerApplicationHistory.enterNumber')}
							/>
							{term && term.length < minNumberDigitsRequired &&
								<span className="text-danger">
										{t('customerApplicationHistory.minNumberDigitsRequired')
											.replace('{value}', minNumberDigitsRequired + '')}
									</span>}
						</div>
					</Row>
				</div>
			</div>
		</Filter>
	);
};

export default CustomerRequestsHistoryFilter;