import * as React from "react";
import { useTranslation } from 'react-i18next';

type Props = {
	statusCode: number;
};

const CustomerRequestStatusCell = ({statusCode }: Props) => {
    const code: number = statusCode || 852250014;

    const { t } = useTranslation();

    return (
        <span>{t(`customerRequestsHistory.status.${code}`)}</span>
    );
};

export default CustomerRequestStatusCell;
